<template>
  <Head>
    <Title>{{ service }}</Title>
  </Head>
</template>

<script setup lang="ts">
const { service } = defineProps({
  service: {
    type: String,
    required: true,
    validator: (value: string) => {
      const allowedValues: string[] = ['MyRenderer', 'CGBank']
      return allowedValues.includes(value)
    },
  },
})

const config = useRuntimeConfig()

const myRendererMetaData = [
  {
    hid: 'og:title',
    property: 'og:title',
    content:
      '「MyRenderer」約１分で想像を形に！空間生成AIサービス',
  },
  {
    hid: 'og:site_name',
    property: 'og:site_name',
    content: 'MyRenderer',
  },
  {
    hid: 'og:type',
    property: 'og:type',
    content: 'website',
  },
  {
    hid: 'og:keywords',
    name: 'keywords',
    content:
       'AI 設計 デザイン インテリア 建築',
  },
  {
    hid: 'og:description',
    name: 'description',
    content:
      '設計デザインの新常識！「MyRenderer」でイメージを形に。約１分であなたのラフスケッチを高品質なデザイン案に変換します。効率化を実現し、クリエイティブな時間を増やしましょう。あなたの創造力を最大限に引き出すお手伝いをします。',
  },
  {
    hid: 'og:locale',
    property: 'og:locale',
    content: 'ja_JP',
  },
  {
    hid: 'og:image',
    property: 'og:image',
    content: '/OGP_1200X630.png',
  },

]

const cgbankMetaData = [
  {
    hid: 'og:title',
    property: 'og:title',
    content:
      '「CGBank」商用利用可能な空間デザインのアイデアを探しに行こう！',
  },
  {
    hid: 'og:site_name',
    property: 'og:site_name',
    content: 'CGBank',
  },
  {
    hid: 'og:type',
    property: 'og:type',
    content: 'website',
  },
  {
    hid: 'og:keywords',
    name: 'keywords',
    content:
      'AI 設計 版権フリー インテリア 建築',
  },
  {
    hid: 'og:description',
    name: 'description',
    content:
      '「CGBank」は、建築やインテリアの設計者様向けに、多様な空間画像を提供するサービスです。商用利用可能な空間のデザイン画像を豊富に取り揃え、あらゆるプロジェクトのクリエイティブなアイデアをサポートします。',
  },
  {
    hid: 'og:locale',
    property: 'og:locale',
    content: 'ja_JP',
  },
  {
    hid: 'og:image',
    property: 'og:image',
    content: '/cgbank-opg.png',
  },
]

const faviconLinks = computed(() => {
  return service === 'MyRenderer'
    ? [
        { rel: 'icon', type: 'image/x-icon', href: '/myrenderer-favicon.ico' },
        { rel: 'icon', type: 'image/png', href: '/myrenderer-favicon.png' },
      ]
    : [
        { rel: 'icon', type: 'image/x-icon', href: '/cgbank-favicon.ico' },
        { rel: 'icon', type: 'image/png', href: '/cgbank-favicon.png' },
      ]
})
const selectedMetaData = computed(() => {
  return service === 'MyRenderer' ? myRendererMetaData : cgbankMetaData
})

if (config.public.environment !== 'production') {
  selectedMetaData.value.push({
    hid: 'robots',
    name: 'robots',
    content: 'noindex',
  })
}

useHead({
  title: service,
  meta: selectedMetaData.value,
  link: faviconLinks.value,
})
</script>
